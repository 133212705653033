const PARTNER_TYPES_ENUM = [
  'Talent hunter',
  'Ambasador',
  'E8 Partner',
]

const PARTNER_TYPES_VARIANTS = {
  'Talent hunter': {
    label: 'Talent hunter',
    variant: 'light-success',
  },
  Ambasador: {
    label: 'Ambasador',
    variant: 'light-warning',
  },
  'E8 Partner': {
    label: 'E8 Partner',
    variant: 'light-danger',
  },
}

const PARTNER_STATES = [
  'Asking payout',
]

const PARTNER_STATES_VARIANTS = {
  'Asking payout': {
    label: 'Asking payout',
    variant: 'light-warning',
  },
}

const PARTNER_PAYOUT_TYPE_VARIANTS = {
  registration: 'light-info',
  discount: 'light-secondary',
}

export {
  PARTNER_TYPES_ENUM, PARTNER_TYPES_VARIANTS, PARTNER_STATES, PARTNER_STATES_VARIANTS, PARTNER_PAYOUT_TYPE_VARIANTS,
}
