import axiosInstance from '@/plugins/axios'

export default {
  getPartner(partnerId) {
    const url = `admin/partner/${partnerId}`

    return axiosInstance.get(url)
      .then(response => response.data)
  },

  confirmWithdrawal(id) {
    const url = `admin/partner-withdrawal/${id}/confirm`

    return axiosInstance.post(url)
      .then(response => response.data)
  },

  changeWithdrawalState(id, state) {
    const url = `admin/partner-withdrawal/${id}/state`

    return axiosInstance.post(url, { state })
      .then(response => response.data)
  },

  removeWithdrawal(id) {
    const url = `admin/partner-withdrawal/${id}`

    return axiosInstance.delete(url)
      .then(response => response.data)
  },

  approveKey(partnerId) {
    const url = `admin/partner/${partnerId}/approve-key`

    return axiosInstance.post(url)
      .then(response => response.data)
  },

  declineKey(partnerId) {
    const url = `admin/partner/${partnerId}/decline-key`

    return axiosInstance.post(url)
      .then(response => response.data)
  },
}
