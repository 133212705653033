<template>
  <gql-table
    ref="partnersKeysGqlTable"
    :fields="fields"
    :query="query"
    query-type="partners"
    sort-by="custom_key_changed_at"
    :sort-desc="false"
  >
    <template #cell(user)="data">
      <b-row>
        <b-col>
          <b-link :to="{ name: 'partner-detail', params: { id: data.item.id } }">
            {{ data.item.user.firstName }} {{ data.item.user.lastName }}
            <br>
            <small>{{ data.item.user.email }}</small>
          </b-link>
        </b-col>
        <b-col>
          <tags-list
            :tags="data.item.user.tags"
            :extras-tags="getUserExtraTags(data.item.user)"
            extras-variant="light-success"
          />
        </b-col>
      </b-row>
    </template>
    <template #cell(affiliate_type)="data">
      <b-badge :variant="partnerTypesVariant[data.item.affiliate_type].variant">
        {{ partnerTypesVariant[data.item.affiliate_type].label }}
      </b-badge>
    </template>

    <template #cell(custom_key_changed_at)="data">
      {{ formatDateTime(data.item.custom_key_changed_at) }}
    </template>

    <template #cell(action)="data">
      <b-button
        v-b-tooltip.hover.left="'Approve key'"
        variant="outline-success"
        class="btn-icon m-50"
        @click="onApproveKey(data.item)"
      >
        <feather-icon icon="UserCheckIcon" />
      </b-button>
      <b-button
        v-b-tooltip.hover.left="'Decline key'"
        variant="outline-warning"
        class="btn-icon m-50"
        @click="onDeclineKey(data.item)"
      >
        <feather-icon icon="XIcon" />
      </b-button>
    </template>
  </gql-table>
</template>
<script>
import {
  BCol, BLink, BRow, BBadge, BButton, VBTooltip,
} from 'bootstrap-vue'
import GqlTable from '@/components/ui/gqltables/GqlTable.vue'
import TagsList from '@/components/page/TagsList.vue'
import { getUserExtraTags } from '@/plugins/userUtils'
import {
  PARTNER_TYPES_ENUM, PARTNER_TYPES_VARIANTS,
} from '@/enums/affiliate'
import { formatDateTime } from '@/plugins/formaters'
import PartnerAPI from '@/api/partner'

const fields = [
  {
    name: 'user',
    label: 'User',
    filterable: {
      type: 'entity', queryType: 'users', value: 'id', label: 'email', search: 'email',
    },
  },
  {
    name: 'affiliate_type', label: 'Affiliate type', filterable: { type: 'multienum', enum: PARTNER_TYPES_ENUM },
  },
  {
    name: 'custom_key_changed_at',
    label: 'Custom key set at',
  },
  {
    name: 'aff_custom_key', label: 'Aff key', filterable: 'like',
  },
  {
    name: 'action',
    label: 'Action',
  },
  {
    name: 'toApprove',
    label: 'toApprove',
    filterable: { type: 'is', visible: false },
    visible: false,
    default: true,
  },
]

const query = [
  'id',
  'affiliate_type',
  'aff_custom_key',
  'referral_custom_key',
  'state',
  'createdAt',
  'custom_key_changed_at',
  {
    user: {
      fields: [
        'firstName',
        'lastName',
        'id',
        'email',
        'kyc_at',
        'createdAt',
        { country: { fields: ['name', 'code2'] } },
        { tags: { fields: [{ tag: { fields: ['name', 'visible', 'color', 'description'] } }, 'createdAt'] } }],
    },
  },
]
export default {
  components: {
    BButton,
    BRow,
    BCol,
    TagsList,
    BLink,
    GqlTable,
    BBadge,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      fields,
      query,
      partnerTypesVariant: PARTNER_TYPES_VARIANTS,
    }
  },
  methods: {
    getUserExtraTags,
    formatDateTime,
    onApproveKey(item) {
      PartnerAPI.approveKey(item.id).then(() => {
        this.$refs.partnersKeysGqlTable.reloadAll(true)
      })
    },
    onDeclineKey(item) {
      PartnerAPI.declineKey(item.id).then(() => {
        this.$refs.partnersKeysGqlTable.reloadAll(true)
      })
    },
  },
}
</script>
